* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.az_laytout_99 {
  display: flex;
}
.az_main {
  width: calc(100% - 200px);
}

.MuiDrawer-paper,
.MuiDrawer-docked {
  width: 250px;
}
.img_wrapper {
  border-radius: 100%;
  overflow: hidden;
  background-color: rgba(255, 232, 232, 0.822);
  border: 1px solid #1976d2;
}
.hidden_input input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.listItemStyle .MuiListItemButton-root:hover .MuiTypography-root,
.listItemStyle .MuiListItemButton-root:hover .MuiSvgIcon-root,
.listItemStyle .MuiListItemButton-root:hover {
  color: #89d106 !important;
  background-color: transparent !important;
}

@media (max-width: 900px) {
  .az_main {
    width: 100%;
  }
}
